import React, { useState, useEffect, Fragment } from "react";

const tableStyle = {
    borderCollapse: "collapse",
    width: "100%",
};

const thStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    width: "auto",
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
};

const trStyle = {
    border: "1px solid #ddd",
    textAlign: "center",
};

const getRandomBoosterMultiplierByTx = (tx) => {
    const createdDate = new Date(tx.CreatedAt);
    const createdAtUnix = createdDate.getMilliseconds();
    const firstInitiateTxHash = tx.initiateTxHash.split(",")[0];
    const initiateTxHash =
        firstInitiateTxHash === "" || firstInitiateTxHash === undefined
            ? 0
            : firstInitiateTxHash.substring(0, 2) === "0x"
            ? +firstInitiateTxHash
            : Number(`0x${firstInitiateTxHash}`);
    const numerator =
        +tx.amount +
        +tx.initiateBlockNumber +
        createdAtUnix +
        +tx.priceByOracle +
        initiateTxHash;
    const randomBoosterSecret = Math.floor(numerator % 100);
    const randomBoosterSecretMultiplier =
        getRandomBoosterMultiplierBySecret(randomBoosterSecret);

    return randomBoosterSecretMultiplier;
};

const getRandomBoosterMultiplierBySecret = (randomBoosterSecret) => {
    switch (true) {
        case randomBoosterSecret < 50:
            return 1;
        case randomBoosterSecret < 75:
            return 2;
        case randomBoosterSecret < 87:
            return 3;
        case randomBoosterSecret < 93:
            return 4;
        case randomBoosterSecret < 97:
            return 5;
        case randomBoosterSecret < 99:
            return 6;
        case randomBoosterSecret === 99:
            return 7;
        default:
            return 0;
    }
};

const StatisticsView = ({
    dailyVolume,
    userWiseVolume,
    userWiseTrade,
    userMultiplierCounts,
}) => {
    const createRow = (key, value, additional = null) => (
        <tr key={key} style={trStyle}>
            <td>{key}</td>
            <td>{value}</td>
            {additional && <td>{additional}</td>}
        </tr>
    );

    const createUserRow = (
        key,
        value,
        trades,
        one,
        two,
        three,
        four,
        five,
        six,
        seven
    ) => (
        <tr key={key} style={trStyle}>
            <td>{key}</td>
            <td>{value}</td>
            <td>{trades}</td>
            <td>{one}</td>
            <td>{two}</td>
            <td>{three}</td>
            <td>{four}</td>
            <td>{five}</td>
            <td>{six}</td>
            <td>{seven}</td>
        </tr>
    );

    return (
        <Fragment>
            <h3>Daily Volume</h3>
            <table style={tableStyle}>
                <thead>
                    <tr style={trStyle}>
                        <th style={thStyle}>Date</th>
                        <th style={thStyle}>Volume</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(dailyVolume).map(([date, volume]) =>
                        createRow(date, volume / 100000000)
                    )}
                </tbody>
            </table>
            <br />
            <h3>User Wise Volume & Trades</h3>
            <table style={tableStyle}>
                <thead>
                    <tr style={trStyle}>
                        <th style={thStyle}>User ID</th>
                        <th style={thStyle}>Volume</th>
                        <th style={thStyle}>Trade Count</th>
                        <th style={thStyle}>1x</th>
                        <th style={thStyle}>2x</th>
                        <th style={thStyle}>3x</th>
                        <th style={thStyle}>4x</th>
                        <th style={thStyle}>5x</th>
                        <th style={thStyle}>6x</th>
                        <th style={thStyle}>7x</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(userWiseVolume).map(([user, volume]) =>
                        createUserRow(
                            user,
                            volume / 100000000,
                            userWiseTrade[user],
                            userMultiplierCounts[user] &&
                                userMultiplierCounts[user].hasOwnProperty(1)
                                ? userMultiplierCounts[user][1]
                                : 0,
                            userMultiplierCounts[user] &&
                                userMultiplierCounts[user].hasOwnProperty(2)
                                ? userMultiplierCounts[user][2]
                                : 0,
                            userMultiplierCounts[user] &&
                                userMultiplierCounts[user].hasOwnProperty(3)
                                ? userMultiplierCounts[user][3]
                                : 0,
                            userMultiplierCounts[user] &&
                                userMultiplierCounts[user].hasOwnProperty(4)
                                ? userMultiplierCounts[user][4]
                                : 0,
                            userMultiplierCounts[user] &&
                                userMultiplierCounts[user].hasOwnProperty(5)
                                ? userMultiplierCounts[user][5]
                                : 0,
                            userMultiplierCounts[user] &&
                                userMultiplierCounts[user].hasOwnProperty(6)
                                ? userMultiplierCounts[user][6]
                                : 0,
                            userMultiplierCounts[user] &&
                                userMultiplierCounts[user].hasOwnProperty(7)
                                ? userMultiplierCounts[user][7]
                                : 0
                        )
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

const App = () => {
    const [data, setData] = useState([]);
    const [detailedData, setDetailedData] = useState([]);
    const [dailyVolume, setDailyVolume] = useState({});
    const [userWiseVolume, setUserWiseVolume] = useState({});
    const [userWiseTrade, setUserWiseTrade] = useState({});
    const [statsView, setStatsView] = useState(false);
    const [currentOrdersCount, setCurrentOrdersCount] = useState(0);
    const [userMultiplierCounts, setUserMultiplierCounts] = useState({});

    // Function to check if an order was created after 17 December
    const isAfterDecember17 = (order) => {
        const createdAtDate = new Date(order.CreatedAt);
        const december17Date = new Date("2023-12-17");

        return createdAtDate > december17Date;
    };

    const performOperation = async () => {
        const orders = await fetch("https://api.wbtc.garden/orders");
        const ordersJson = await orders.json();
        // Filter orders created after 17 December
        const filteredOrders = ordersJson.filter(isAfterDecember17);
        const ordersSortedByScore = filteredOrders.sort((a, b) => b.ID - a.ID);
        setData(ordersSortedByScore);

        const details = {};
        const batchSize = 10;

        const fetchOrderDetails = async (order) => {
            const detailedOrder = await fetch(
                `https://api.wbtc.garden/orders/${order.ID}`
            );
            const detailedOrderJson = await detailedOrder.json();
            details[detailedOrderJson.ID] = {
                ...detailedOrderJson,
                secretMultiplier: getRandomBoosterMultiplierByTx(
                    detailedOrderJson.initiatorAtomicSwap
                ),
            };
            setDetailedData(details);
        };

        for (let i = 0; i < filteredOrders.length; i += batchSize) {
            const batch = filteredOrders.slice(i, i + batchSize);
            const promises = batch.map(fetchOrderDetails);
            await Promise.all(promises);
            setCurrentOrdersCount(i);
        }

        setCurrentOrdersCount(filteredOrders.length);

        const dailyVolume = {};
        Object.values(details).forEach((order) => {
            const createdAt = new Date(order.CreatedAt);
            const noonToNoonDate = new Date(createdAt.getTime());

            // noonToNoonDate.setUTCHours(noonToNoonDate.getUTCHours() - 12);

            // if (createdAt.getUTCHours() < 12) {
                // noonToNoonDate.setUTCDate(noonToNoonDate.getUTCDate() - 1);
            // }

            // noonToNoonDate.setUTCHours(0, 0, 0, 0);

            const dateKey = noonToNoonDate.toISOString().split("T")[0];
            if (
                order.initiatorAtomicSwap &&
                order.initiatorAtomicSwap.swapStatus === 4
            ) {
                dailyVolume[dateKey] =
                    (dailyVolume[dateKey] || 0) +
                    parseInt(order.initiatorAtomicSwap.amount, 10);
            }
        });

        const sortedDailyVolume = Object.fromEntries(
            Object.entries(dailyVolume).sort(
                (a, b) => new Date(b[0]) - new Date(a[0])
            )
        );
        setDailyVolume(sortedDailyVolume);

        const userWiseVolume = {};
        Object.values(details).forEach((order) => {
            const user = order.maker;

            if (
                order.initiatorAtomicSwap &&
                order.initiatorAtomicSwap.swapStatus === 4
            ) {
                userWiseVolume[user] =
                    (userWiseVolume[user] || 0) +
                    parseInt(order.initiatorAtomicSwap.amount, 10);
            }
        });

        const sortedUserWiseVolumeCount = Object.fromEntries(
            Object.entries(userWiseVolume).sort((a, b) => b[1] - a[1])
        );

        setUserWiseVolume(sortedUserWiseVolumeCount);

        const userWiseTradeCount = {};
        Object.values(details).forEach((order) => {
            const user = order.maker;

            if (
                order.initiatorAtomicSwap &&
                order.initiatorAtomicSwap.swapStatus === 4
            ) {
                userWiseTradeCount[user] = (userWiseTradeCount[user] || 0) + 1;
            }
        });

        const sortedUserWiseTradeCount = Object.fromEntries(
            Object.entries(userWiseTradeCount).sort((a, b) => b[1] - a[1])
        );

        setUserWiseTrade(sortedUserWiseTradeCount);

        const userOrderCounts = {};

        Object.values(details).forEach((order) => {
            const maker = order.maker;
            const multiplier = order.secretMultiplier;

            // Check if the maker is already in the userOrderCounts object
            if (!userOrderCounts[maker]) {
                userOrderCounts[maker] = {};
            }

            // Increment the count for the specific multiplier
            if (!userOrderCounts[maker][multiplier]) {
                userOrderCounts[maker][multiplier] = 1;
            } else {
                userOrderCounts[maker][multiplier]++;
            }
        });

        console.log(userOrderCounts);

        setUserMultiplierCounts(userOrderCounts);
    };

    useEffect(() => {
        performOperation();
    }, []);

    const shortenChainName = (address) => {
        if (address === "ethereum:0xA5E38d098b54C00F10e32E51647086232a9A0afD") {
            return `eth`;
        } else if (
            address === "ethereum_arbitrum:0x203DAC25763aE783Ad532A035FfF33d8df9437eE"
        ) {
            return `arb`;
        } else if (address === "bitcoin") {
            return `btc`;
        } else {
            return "NA";
        }
    };

    return (
        <div>
            <h1>Transaction Data</h1>
            <br />
            <h4>Loading Orders {`(${currentOrdersCount}/${data.length})`}</h4>
            <button onClick={() => setStatsView(!statsView)}>
                {statsView ? "Hide" : "Display"} Statistics
            </button>
            <br />
            {statsView && (
                <StatisticsView
                    userWiseTrade={userWiseTrade}
                    userWiseVolume={userWiseVolume}
                    dailyVolume={dailyVolume}
                    userMultiplierCounts={userMultiplierCounts}
                />
            )}
            <br />
            <table style={tableStyle}>
                <thead>
                    <tr style={trStyle}>
                        <th style={thStyle}>ID</th>
                        <th style={{ ...thStyle, width: "20px" }}>Init Chain</th>
                        <th style={{ ...thStyle, width: "20px" }}>Redeem Chain</th>
                        <th style={thStyle}>User address</th>
                        <th style={thStyle}>Secret Multiplier</th>
                        <th style={thStyle}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.ID} style={trStyle}>
                            <td>{item.ID}</td>
                            <td>{shortenChainName(item.orderPair.split("-")[0])}</td>
                            <td>{shortenChainName(item.orderPair.split("-")[1])}</td>
                            <td>{item.maker}</td>
                            {detailedData[item.ID] && (
                                <Fragment>
                                    <td>{detailedData[item.ID].secretMultiplier}</td>
                                    <td>
                                        {detailedData[item.ID].initiatorAtomicSwap?.amount /
                                            100000000}
                                    </td>
                                </Fragment>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default App;